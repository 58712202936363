<template>
  <div class="register">
    <div class="card" @keyup.enter="Register">
      <div>
        <!-- 标题 -->
        <h1 class="center">Sign up</h1>
        <div class="center">
          <a-typography-text type="secondary">
            已经有了账号?
            <a-typography-link target="_blank">
              <router-link to="/login">点击这里去登录</router-link>
            </a-typography-link>
          </a-typography-text>
        </div>
        <MyDivider/>
        <div class="center">
          <a-input
              v-model:value="RegisterForm.username"
              placeholder="请输入用户名"
              style="width: 350px; height: 50px"
          >
            <template #prefix>
              <user-outlined/>
            </template>
          </a-input>
          <br/>
          <a-popover>
            <template #content>
              <p>密码不得小于六位，Thank you</p>
            </template>
            <a-input-password
                v-model:value="RegisterForm.password"
                placeholder="请输入密码"
                style="width: 350px; height: 50px"
            >
              <template #prefix>
                <LockOutlined/>
              </template>
            </a-input-password>
          </a-popover>
          <br/>
          <a-popover>
            <template #content>
              <p>密码不得小于六位，Thank you</p>
            </template>
            <a-input-password
                v-model:value="RegisterForm.checkPassword"
                placeholder="请再次输入密码"
                style="width: 350px; height: 50px"
            >
              <template #prefix>
                <LockOutlined/>
              </template>
            </a-input-password>
          </a-popover>
        </div>
        <br/>
        <!-- 记住登录 -->
        <div style="display: flex; justify-content: space-between">
          <a-checkbox v-model:checked="RegisterForm.remember"
          >直接登录
          </a-checkbox>
          <!--          <a-typography-link target="_blank"> 忘记了密码?</a-typography-link>-->
        </div>
        <br/>
        <!-- 注册按钮 -->
        <div class="center">
          <a-button
              type="primary"
              shape="round"
              size="large"
              style="width: 275px; height: 40px"
              @click="Register"
          >注册
          </a-button>
          <MyDivider/>
        </div>
        <!-- 版权信息 -->
        <!--        <div class="center">-->
        <!--          <p>-->
        <!--            ©2023-->
        <!--            <a-typography-link href="https://www.zueb.edu.cn/" target="_blank">-->
        <!--              郑州经贸学院-->
        <!--            </a-typography-link>-->
        <!--            所有权-->
        <!--          </p>-->
        <!--        </div>-->
        <!--        <router-link to="/search">游客访问</router-link>-->
        <!--        &emsp14;&emsp14;&emsp14;-->
        <!--        <router-link to="/out">测试页面</router-link>-->
      </div>
    </div>
  </div>
</template>

<script setup>
import {LockOutlined, UserOutlined} from "@ant-design/icons-vue";
import {ref} from "vue";
import {useRouter} from "vue-router";
import MyDivider from "@/components/Divider/HDivider.vue";
import {message} from "ant-design-vue";
import {register} from "@/api/loginAPI";
import {setToken} from "@/utils/cookie";

const router = useRouter();
const RegisterForm = ref({
  username: "",
  password: "12345678",
  checkPassword: "12345678",
  remember: false,
});
const Register = () => {
  register(RegisterForm.value).then(res => {
    // 注册成功后跳转到指定页面
    message.loading(null, 1).then(() => {
      if (res.code === 20000) {
        message.success("注册成功", 2.5);
        if (RegisterForm.value.remember) {
          router.push("/search");
        } else {
          setToken(res.data)
          router.push("/login");
        }
      } else {
        message.error(res.message);
      }
    });
  });
};
</script>

<style scoped>
.register {
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/登录背景.jpeg");
  background-size: cover; /* 背景图像的尺寸 */
  background-attachment: fixed; /* 背景图像固定不动 */
}

.center {
  display: grid;
  place-items: center;
}

.card {
  display: grid;
  place-items: center;
  width: 470px;
  height: 580px;
  background: #fdfdfd;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease; /* 添加 box-shadow 的过渡效果 */
}

.card:hover {
  transform: translate(-5px, -5px); /* 向上浮动10像素 */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

h1 {
  display: grid;
  place-items: center;
  font-size: 38px;
  margin-bottom: 24px;
  font-weight: bold;
}
</style>
