import {createRouter, createWebHashHistory,} from "vue-router";
import LoginPage from "@/pages/LoginPages/LoginPage.vue";
import RegisterPage from "@/pages/LoginPages/RegisterPage.vue";
import ForgetPage from "@/pages/LoginPages/ForgetPage.vue";
import SearchPage from "@/pages/Home/SearchPage.vue";
import StepsPage from "@/components/StartUpMenu/StepsPage.vue";
import TestPage from "@/pages/Home/testPage.vue";
import BookPage from "@/pages/Home/BookPage.vue";
import {getToken} from "@/utils/cookie";
import {message} from "ant-design-vue";
import RecommendPage from "@/pages/Home/RecommendPage.vue";
import ListPage from "@/pages/Home/ListPage.vue";
import EchartsPage from "@/pages/Home/EchartsPage.vue";

const routes = [
        {
            path: "/",
            component: SearchPage,
            meta: {
                title: "主页",
            },
        }, {
            path: "/search",
            name: "search",
            component: SearchPage,
            meta: {
                title: "主页",
            },
        }, {
            path: "/recommend",
            name: "recommend",
            component: RecommendPage,
            meta: {
                title: "猜你喜欢",
            },
        }, {
            path: "/list",
            name: "list",
            component: ListPage,
            meta: {
                title: "排行榜",
            },
        }, {
            path: "/login",
            component: LoginPage,
            meta: {
                title: "登录",
            },
        }, {
            path: "/register",
            component: RegisterPage,
            meta: {
                title: "注册",
            },
        }, {
            path: "/forget",
            component: ForgetPage,
            meta: {
                title: "重置密码",
            },
        }, {
            path: "/startUp",
            component: StepsPage,
            meta: {
                title: "快捷启动",
            },
        }, {
            path: "/test",
            component: TestPage,
            meta: {title: "测试页面",}
        }, {
            path: "/book/:id",
            component: BookPage,
            meta: {title: "小说详情"}
        }, {
            path: "/echarts",
            component: EchartsPage,
            meta: {title: "图表分析"}
        }
    ]
;

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = (to.meta && to.meta.title) || "默认标题";

    if (to.matched.some(record => record.meta.requiresAuth)) { // 判断该路由是否需要进行鉴权
        // 获取token
        if (getToken() === undefined) { // token不存在，则跳转到登录页
            message.error("请先登录")
            next('/login');
        } else {
            next();
        }
    } else {  // 不需要进行鉴权，直接进行下一步路由
        next();
    }
});


export default router;
