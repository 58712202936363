<template>
  <div style="margin: auto">
    <a-steps :current="current" :items="items"></a-steps>
    <div class="steps-content">
      {{ steps[current].content }}
    </div>
    <div class="steps-action">
      <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
      <a-button v-if="current === steps.length - 1" type="primary" @click="done">Done</a-button>
      <a-button v-if="current > 0" style="margin-left: 8px" @click="back">Back</a-button>
    </div>
  </div>
</template>
<script setup>
import {ref} from 'vue';
import {message} from "ant-design-vue";
import LoginPage from "@/pages/LoginPages/LoginPage.vue";

const current = ref(0);
const next = () => {
  current.value++;
};
const done = () => {
  message.success('Processing complete!--任务完成')
};
const back = () => {
  current.value--;
};
const steps = [
  {
    title: 'First',
    content: LoginPage,
  },
  {
    title: 'Second',
    content: 'Second-content',
  },
  {
    title: 'Last',
    content: 'Last-content',
  },
];
const items = steps.map(item => ({
  key: item.title,
  title: item.title,
}));
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme='dark'] .steps-content {
  background-color: #2f2f2f;
  border: 1px dashed #404040;
}
</style>
