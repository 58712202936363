<template>
  <div class="h-divider"></div>
</template>

<script setup>
</script>

<style scoped>
.h-divider {
  height: 10px;
}
</style>
