import request from "@/utils/request";

export function login(form) {
    return request.post("/login", form);
}

export function logout() {
    return request.post("/logout");
}

export function register(from) {
    return request.post("/register", from);
}


export function isLogin() {
    return request.post("/isLogin");
}
