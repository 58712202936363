<template>
  <div class="footer">
    <a-typography-text type="secondary">
      本站作品、资源均收集于网络仅供用于学习和交流，本站一切资源不代表本站立场，我们尊重图书和小说作者的版权，如有不妥请联系本站处理！
      <a-button type="link" @click="Contact">联系作者</a-button>
    </a-typography-text>
  </div>
</template>

<script setup>
import {Modal} from "ant-design-vue";
import {h} from "vue";

const Contact = () => {
  Modal.info({
    title: '联系本站',
    okText: "确认",
    content: h('div', {}, [
      h('p', '联系方式：1984469466@qq.com'),
      h('p', '本站问题请在标题注明[阅读指南针]，其他问题概不回复'),
    ]),
  });
};
</script>

<style scoped>
.footer {
  display: grid;
  place-items: center;
  height: 100px;
  background-color: #ffffff;
}
</style>
