<template>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
  </transition>
  <a-back-top/>
</template>

<style>
.ant-image-img {
  cursor: pointer;
  transition: all 0.6s;
}

.ant-image-img:hover {
  border-radius: 5px;
  transform: scale(1.2);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

div {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
</style>
<script setup>

</script>
