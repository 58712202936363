import request from "@/utils/request";

export function liuda() {
    return request.post("/user/liuda");
}

export function getInfo() {
    return request.post("/user/getInfo");
}

export function setInfo() {
    return request.post("/user/setInfo");
}


