<template>
  <div>
    <HeaderPage/>
    <a-card :bordered="false" style="width: 100%">
      <a-card-grid class="image-containers">
        <div class="echarts" id="main1"></div>
      </a-card-grid>
      <a-card-grid class="image-containers">
        <div class="echarts" id="main2"></div>
      </a-card-grid>
      <a-card-grid class="image-containers">
        <div class="echarts" id="main3"></div>
      </a-card-grid>
      <a-card-grid class="image-containers">
        <div class="echarts" id="main4"></div>
      </a-card-grid>
    </a-card>
  </div>
</template>

<script setup>
import * as echarts from 'echarts';
import {ref} from "vue";
import {getRankBook5, getRateRankBook, getTypeHotBook, getVisitsRankBook} from "@/api/homeAPI";
import HeaderPage from "@/pages/Home/HeaderPage.vue";

const echarts1 = ref([])
const echarts2 = ref([])
const echarts3 = ref([])
const echarts4 = ref([])
getRankBook5().then(res => {
  echarts1.value = res.data
  init1()
})
getVisitsRankBook().then(res => {
  echarts2.value = res.data
  init2()
})
getRateRankBook().then(res => {
  echarts3.value = res.data
  init3()
})
getTypeHotBook().then(res => {
  echarts4.value = res.data
  init4()
})

// 在组件挂载完成后执行初始化操作
function init1() {
  window.addEventListener('resize', function () {
    myChart1.resize();
  });
  const myChart1 = echarts.init(document.getElementById('main1'));
  myChart1.setOption({
    title: {
      text: '推荐与收藏总数TOP10'
    },
    xAxis: {
      name: '书名', // 添加 x 轴标签说明
      data: echarts1.value.map(item => item.bookName),
      axisLabel: {
        rotate: 25, // 设置标签旋转角度为 45 度
        fontSize: 12, // 设置标签的字体大小为 12px
        margin: 15, // 设置标签与轴线之间的距离为 10px
      }
    },
    yAxis: {
      name: '推荐及收藏数量' // 添加 y 轴标签说明
    },
    legend: {
      data: ['推荐量', '收藏量'] // 图例中显示的文本，可以根据实际情况调整
    },
    tooltip: {
      trigger: 'axis', // 触发类型为悬停在数据点上
      axisPointer: {
        type: 'shadow' // 设置 axisPointer 类型为阴影指示线
      },
      formatter: '{b}<br>推荐👍：{c0} <br>收藏📖：{c1}'
    },
    series: [
      {
        name: '推荐量', // 对应图例中的文本
        data: echarts1.value.map(item => item.likeCount),
        type: 'bar',
        stack: 'x'
      },
      {
        name: '收藏量', // 对应图例中的文本
        data: echarts1.value.map(item => item.collectionCount),
        type: 'bar',
        stack: 'x'
      }
    ]
  })
}

function init2() {
  window.addEventListener('resize', function () {
    myChart2.resize();
  });
  const myChart2 = echarts.init(document.getElementById('main2'));
  myChart2.setOption({
    title: {
      text: '访问量TOP10'
    },
    xAxis: {
      name: '书名', // 添加 x 轴标签说明
      data: echarts2.value.map(item => item.bookName),
      axisLabel: {
        rotate: 25, // 设置标签旋转角度为 45 度
        fontSize: 12, // 设置标签的字体大小为 12px
        margin: 15, // 设置标签与轴线之间的距离为 10px
      }
    },
    yAxis: {
      name: '访问量' // 添加 y 轴标签说明
    },
    tooltip: {
      trigger: 'axis', // 触发类型为悬停在数据点上
      axisPointer: {
        type: 'shadow' // 设置 axisPointer 类型为阴影指示线
      },
      formatter: '{b}<br>访问量🚪：{c}'
    },
    legend: {
      data: ['访问量'] // 图例中显示的文本
    },
    series: [
      {
        name: '访问量', // 需要对应图例中的文本
        data: echarts2.value.map(item => item.visits),
        type: 'line',
        stack: 'x'
      }
    ]
  })
}

function init3() {
  window.addEventListener('resize', function () {
    myChart3.resize();
  });
  const myChart3 = echarts.init(document.getElementById('main3'));
  myChart3.setOption({
    title: {
      text: '评分TOP10'
    },
    xAxis: {
      name: '书名', // 添加 x 轴标签说明
      data: echarts3.value.map(item => item.bookName),
      axisLabel: {
        rotate: 25, // 设置标签旋转角度为 45 度
        fontSize: 12, // 设置标签的字体大小为 12px
        margin: 15, // 设置标签与轴线之间的距离为 10px
      }
    },
    yAxis: {
      name: '评分' // 添加 y 轴标签说明
    },
    tooltip: {
      trigger: 'axis', // 触发类型为悬停在数据点上
      axisPointer: {
        type: 'shadow' // 设置 axisPointer 类型为阴影指示线
      },
      formatter: '{b}<br>评分✨：{c}'
    },
    legend: {
      data: ['评分'] // 图例中显示的文本
    }
    ,
    series: [
      {
        name: '评分', // 对应图例中的文本
        data: echarts3.value.map(item => item.rate),
        type: 'line',
        stack: 'x'
      }
    ]
  })
}

function init4() {
  window.addEventListener('resize', function () {
    myChart4.resize();
  });
  const myChart4 = echarts.init(document.getElementById('main4'));
  myChart4.setOption({
    title: {
      text: '小说类型热度占比',
      left: 'left'
    },
    tooltip: {
      trigger: 'item',
      formatter: '类型📚：{b}<br>热度🔥：{c} <br>占比🥘：({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      data: echarts4.value.map(item => item.type),
    },
    series: [
      {
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: echarts4.value.map(item => ({name: item.type, value: item.total_hot})),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  })
}
</script>

<style scoped>
.image-containers {
  width: 50%;
  height: 600px;
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>
