import request from "@/utils/request";

export function getBookById(id) {
    return request.post("/book/get/" + id);
}

export function likeBookById(id) {
    return request.post("/book/like/" + id);
}

export function collectionBookById(id) {
    return request.post("/book/collection/" + id);
}

export function bookshelfBookById(id) {
    return request.post("/book/bookshelf/" + id);
}

export function rateBookById(id, useRate) {
    return request.post("/book/rate/" + id + "&" + useRate);
}

export function commentBookById(from) {
    return request.post("/book/comment", from);
}



