import request from "@/utils/request";


export function isLogin() {
    return request.post("/isLogin");
}

export function searchBookList(from) {
    return request.post("/book/searchBookList", from);
}

export function searchTypeList(from) {
    return request.post("/book/searchTypeList", from);
}

export function recommendBook() {
    return request.post("/home/recommend");
}

export function visitsList() {
    return request.post("/home/visitsList");
}

export function likeList() {
    return request.post("/home/likeList");
}

export function bookshelfList() {
    return request.post("/home/bookshelfList");
}

export function collectionList() {
    return request.post("/home/collectionList");
}



export function getRankBook5() {
    return request.post("/echarts/getRankBook");
}

export function getVisitsRankBook() {
    return request.post("/echarts/getVisitsRankBook");
}

export function getRateRankBook() {
    return request.post("/echarts/getRateRankBook");
}

export function getTypeHotBook() {
    return request.post("/echarts/getTypeHotBook");
}

