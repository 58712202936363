<template>
  <div class="recommend">
    <HeaderPage/>
    <a-flex justify="center">
      <div class="content">
        <h3>访问量排行榜</h3>
        <a-list size="small" bordered :data-source="visitsData">
          <template #renderItem="{ item }">
            <a-list-item><a @click="bookClick(item.id)">{{ item.bookName }}</a><a
                style="float: right">{{ item.visits }}</a>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <div class="content">
        <h3>推荐排行榜</h3>
        <a-list size="small" bordered :data-source="likeData">
          <template #renderItem="{ item }">
            <a-list-item><a @click="bookClick(item.id)">{{ item.bookName }}</a><a
                style="float: right">{{ item.likeCount }}</a>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <div class="content">
        <h3>加入书架排行榜</h3>
        <a-list size="small" bordered :data-source="bookshelfData">
          <template #renderItem="{ item }">
            <a-list-item><a @click="bookClick(item.id)">{{ item.bookName }}</a><a
                style="float: right">{{ item.bookshelfCount }}</a>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <div class="content">
        <h3>收藏排行榜</h3>
        <a-list size="small" bordered :data-source="collectionData">
          <template #renderItem="{ item }">
            <a-list-item><a @click="bookClick(item.id)">{{ item.bookName }}</a><a
                style="float: right">{{ item.collectionCount }}</a>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <WDivider/>
    </a-flex>
  </div>
  <FooterPage/>
</template>
<script setup>
import HeaderPage from "@/pages/Home/HeaderPage.vue";
import FooterPage from "@/pages/Home/FooterPage.vue";
import WDivider from "@/components/Divider/WDivider.vue";
import {ref} from "vue";
import {bookshelfList, collectionList, likeList, visitsList} from "@/api/homeAPI";
import router from "@/utils/router";

const visitsData = ref([])
const likeData = ref([])
const bookshelfData = ref([])
const collectionData = ref([])
const bookClick = (id) => {
  // message.success("进入书籍详情")
  router.push({
    path: "/book/" + id,
  })
}
visitsList().then(res => {
  visitsData.value = res.data
})
likeList().then(res => {
  likeData.value = res.data
})
bookshelfList().then(res => {
  bookshelfData.value = res.data
})
collectionList().then(res => {
  collectionData.value = res.data
})
</script>
<style scoped>
.search {
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
}

.content {
  border-radius: 10px; /* 圆角半径 */
  width: 300px;
  height: auto;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #fbfbfb;
}

p {
  max-height: 300px;
  font-size: 14px;
  padding: 0 5px 0 5px;
  /* 设置文本溢出时的处理方式 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-indent: 30px; /* 设置首行缩进 */
  -webkit-line-clamp: 3; /* 设置最多显示3行 */
  text-overflow: ellipsis; /* 显示省略号 */
}
</style>
