<template>
  <div class="w-divider"></div>
</template>

<script setup>
</script>

<style scoped>
.w-divider {
  width: 10px;
}
</style>
