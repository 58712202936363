<script setup>
import {reactive, ref} from "vue";
import HDivider from "@/components/Divider/HDivider.vue";
import router from "@/utils/router";
import {useRoute} from "vue-router";
import {useUserStore} from "@/pinia/pinia";
import WDivider from "@/components/Divider/WDivider.vue";
import {DownOutlined,} from '@ant-design/icons-vue';
import {logout} from "@/api/loginAPI";
import {message} from "ant-design-vue";

const menuKeys = ref(["0"])
if (window.location.href.indexOf("recommend") !== -1) {
  menuKeys.value[0] = "recommend";
} else if (window.location.href.indexOf("search") !== -1) {
  menuKeys.value[0] = "search";
} else if (window.location.href.indexOf("list") !== -1) {
  menuKeys.value[0] = "list";
} else if (window.location.href.indexOf("echarts") !== -1) {
  menuKeys.value[0] = "echarts";
}
const route = useRoute()
const from = reactive({
  type: "全部",
  searchValue: route.query.searchValue || "",
  page: '1',
  rows: '20'
})
const searchBook = () => {
  // message.success("触发头部搜索")
  router.push({
    path: "/search",
    query: from
  })
}
const store = useUserStore();
const head_img = "/logo.png";
const LogOut = () => {
  logout().then(res => {
    if (res.code === 20000) message.success("成功退出")
    store.fedLogOut()
    router.push("/login")
  })
}
store.getInfo()
</script>

<template>
  <a-affix :offset-top="0">
    <div id="header" class="header">
      <a><img :width="300" :src="head_img" alt="logo"/></a>
      <a-menu
          theme="light"
          mode="horizontal"
          v-model:selectedKeys.trim="menuKeys"
          :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="search">
          <router-link to="/search?type=全部&searchValue=&page=1&rows=20">首页</router-link>
        </a-menu-item>
        <a-menu-item key="recommend">
          <router-link to="/recommend">猜你喜欢</router-link>
        </a-menu-item>
        <a-menu-item key="list">
          <router-link to="/list">排行榜</router-link>
        </a-menu-item>
        <a-menu-item key="echarts">
          <router-link to="/echarts">图表分析</router-link>
        </a-menu-item>
      </a-menu>
      <a-input-search
          v-model:value="from.searchValue"
          placeholder="请输入作品名称或作者名"
          style="width: 300px"
          @search="searchBook"
      />
      <WDivider/>
      <div>
        <a-avatar :size="40" :src="store.headImg"/>
        <a-divider type="vertical"/>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            {{ store.username }}
            <DownOutlined/>
          </a>

          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click="LogOut">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <HDivider/>
  </a-affix>
</template>

<style scoped>

.header {
  text-align: center;
  background-color: #fdfdfd;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
